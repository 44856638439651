import React from 'react';
import PropTypes from 'prop-types';
import { ModalWrapper, ModalInner } from './Modal.styled';
import Button from '../button/Button';

function ModalContent(props) {
  const {
    children,
    hide,
    outerCss,
    innerCss,
    hasImageHeader,
    isSelectUserContent,
  } = props;

  return (
    <ModalWrapper
      aria-modal
      aria-hidden
      tabIndex={-1}
      role="dialog"
      css={outerCss}
    >
      <ModalInner
        hasImageHeader={hasImageHeader}
        isSelectUserContent={isSelectUserContent}
        css={innerCss}
      >
        <Button
          type="circle"
          actionType="onclick"
          onClick={hide}
          svgIcon="close-big"
          colorTheme="gray"
          css={{
            zIndex: '$max',
            position: 'absolute',
            right: '$8',
            top: '$8',
          }}
        />
        {children}
      </ModalInner>
    </ModalWrapper>
  );
}

ModalContent.propTypes = {
  children: PropTypes.node,
};

ModalContent.defaultProps = {
  children: undefined,
};

export default ModalContent;
