import { styled, keyframes } from '@root/stitches.config';
import { Link } from 'gatsby';

const buttonStyled = {
  color: '$white',
  fontSize: '$16',
  lineHeight: '1.25',
  display: 'inline-flex',
  fontFamily: 'ArtifaktElementBold',
  letterSpacing: '.2px',
  justifyContent: 'center',
  alignItems: 'center',
  position: 'relative',
  cursor: 'pointer',
  border: 'none',
  textDecoration: 'none',
  textAlign: 'center',

  '&:hover,&:focus': {
    transition: 'background-color .5s ease',
  },

  variants: {
    hasChildren: {
      hasChildren: {
        '&:hover,&:focus': {
          transition: 'background-color .5s ease',
        },
      },
    },
    circleSize: {
      small: {
        width: '$32',
        height: '$32',
        minWidth: '$32',
        minHeight: '$32',
        padding: '0px',
        svg: {
          width: '$16',
          height: '$16',
        },
      },
      normal: {
        svg: {
          width: '$24',
          height: '$24',
        },
      },
      large: {
        svg: {
          width: '$32',
          height: '$32',
        },
      },
    },
    colortheme: {
      elements: {
        backgroundColor: '$elements',
        color: '$blackToWhite',
        transition: 'color .3s ease',
        svg: {
          fill: '$blackToWhite',
        },
      },
      normal: {
        backgroundColor: '$blackToWhite',
        color: '$whiteToBlack',
        transition: 'color .3s ease',
        svg: {
          fill: '$whiteToBlack',
        },
        '&:hover,&:focus': {
          backgroundColor: '$blackToWhite',
          outline: '1px solid $purple',
          svg: {
            fill: '$black',
          },
        },
      },
      normalReverse: {
        backgroundColor: '$whiteToBlack',
        color: '$blackToWhite',
        transition: 'color .3s ease',
        svg: {
          fill: '$blackToWhite',
        },
      },
      black: {
        backgroundColor: '$black',
        color: '$white',
        transition: 'color .3s ease',
        svg: {
          fill: '$white',
        },
      },
      reverse: {
        backgroundColor: '$black',
        color: '$white',
        transition: 'color .3s ease',
        svg: {
          fill: '$white',
        },
        '&:hover,&:focus': {
          backgroundColor: '$black',
          outline: '1px solid $purple',
          svg: {
            fill: '$black',
          },
        },
      },
      headerButtons: {
        backgroundColor: '$headerButtons',
        color: '$white',
        transition: 'color .3s ease',
        svg: {
          fill: 'white',
        },
        '&:hover,&:focus': {
          outline: '1px solid $purple',
        },
      },
      white: {
        backgroundColor: '$white',
        color: '$black',
        svg: {
          fill: '$black',
        },
        transition: 'backgroundColor .3s ease',
        '&:hover,&:focus': {
          outline: '1px solid $purple',
        },
      },
      whiteToBlack: {
        backgroundColor: '$white',
        color: '$black',
        svg: {
          fill: '$black',
        },
        transition: 'color .3s ease',
        transition: 'backgroundColor .3s ease',

        '&:hover,&:focus': {
          backgroundColor: '$black',
          color: '$white',
          svg: {
            fill: '$white',
          },
        },
      },
      gray: {
        backgroundColor: '$lightGrey',
        color: '$blackToWhite',
        transition: 'color 500',
        svg: {
          fill: '$blackToWhite',
        },

        '&:hover,&:focus': {
          backgroundColor: '$lightGrey',
          outline: '1px solid $purple',
          svg: {
            fill: '$blackToWhite',
          },
        },
      },
      lightgray: {
        backgroundColor: '$lightGrey',
        color: '$white',
        svg: {
          fill: '$white',
        },
      },
      red: {
        backgroundColor: '$red',
        color: '$white',
        svg: {
          fill: '$white',
        },
        transition: 'backgroundColor .3s ease',
        '&:hover,&:focus': {
          backgroundColor: '$red',
          outline: '1px solid $purple',
        },
      },
      outlined: {
        backgroundColor: 'transparent',
        color: '$blackToWhite',
        transition: 'all .3s ease',
        border: '1px solid $blackToWhite',

        '&:hover,&:focus': {
          backgroundColor: 'transparent',
          color: '$blackToWhite',
          transition: 'all .1s ease',
        },
        transition: 'backgroundColor .3s ease',
        '&:hover,&:focus': {
          backgroundColor: '$transparent',
          outline: '1px solid $purple',
        },
      },
      transparent: {
        backgroundColor: 'transparent',
        color: '$blackToWhite',
        svg: {
          fill: '$blackToWhite',
        },
        '&:hover,&:focus': {
          '@bp2': {
            backgroundColor: '$elements',
            // color: '$blackToWhite',
            transition: 'color 500',
            svg: {
              // fill: '$blackToWhite',
            },
          },
        },
      },
      transparentFull: {
        backgroundColor: 'transparent',
        color: '$blackToWhite',
        svg: {
          fill: '$blackToWhite',
        },
        '&:hover,&:focus': {
          backgroundColor: 'transparent !important',
        },
      },
      transparentWhite: {
        backgroundColor: 'transparent',
        color: '$white',
        svg: {
          fill: '$white',
        },
        '&:hover,&:focus': {
          backgroundColor: 'transparent',
          svg: {
            fill: '$blackToWhite',
          },
        },
      },
      transparentGray: {
        backgroundColor: 'transparent',
        transition: 'color .3s ease',
        justifyContent: 'flex-end',
        svg: {
          fill: 'rgb(256, 256, 256,0.5)',
        },
        '&:hover,&:focus': {
          backgroundColor: 'transparent',
          svg: {
            fill: '$blackToWhite',
          },
        },
      },
      reactions: {
        backgroundColor: '$white',
        '&:hover,&:focus': {
          backgroundColor: 'rgba(0,0,0,.2)',
          transition: 'background-color .5s ease',
        },
      },
      reactionsInactive: {
        stroke: '$white',
        strokeWidth: '$3',
        fill: 'rgba(0, 0, 0, 0.5)',
        backgroundColor: 'transparent',
        '&:hover,&:focus': {
          backgroundColor: 'rgba(0,0,0,.2)',
          transition: 'background-color .5s ease',
        },
      },
      linkedin: {
        backgroundColor: '$linkedin',
        color: '$white',
        transition: 'color .3s ease',
        svg: {
          fill: '$white',
        },
      },
      google: {
        backgroundColor: 'transparent',
        color: '$blackToWhite',
        transition: 'all .3s ease',
        border: '1px solid $grey',

        '&:hover,&:focus': {
          backgroundColor: 'transparent',
          color: '$blackToWhite',
          transition: 'all .1s ease',
        },
        transition: 'backgroundColor .3s ease',
        '&:hover,&:focus': {
          backgroundColor: '$transparent',
          outline: '1px solid $purple',
        },
      },
      apple: {
        backgroundColor: 'transparent',
        color: '$blackToWhite',
        transition: 'all .3s ease',
        border: '1px solid $grey',

        '&:hover,&:focus': {
          backgroundColor: 'transparent',
          color: '$blackToWhite',
          transition: 'all .1s ease',
        },
        transition: 'backgroundColor .3s ease',
        '&:hover,&:focus': {
          backgroundColor: '$transparent',
          outline: '1px solid $purple',
        },
      },
      adsk: {
        backgroundColor: '$black',
        color: '$white',
        transition: 'color .1s ease',
        svg: {
          fill: '$white',
        },
        '&:hover,&:focus': {
          backgroundColor: '$black',
          outline: '1px solid $purple',
        },
      },
    },
    type: {
      circle: {
        width: '46px',
        height: '46px',
        minWidth: '46px',
        minHeight: '46px',
        borderRadius: '50%',
        fontFamily: 'var(--ArtifaktElementBold)',
        '&:focus': {
          boxShadow: '0 0 0 2pt $darkGrey',
          outline: '0',
        },
        span: {
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        },
      },
      link: {
        width: 'auto',
        padding: '0',
        color: '$black',
        'span + span': {
          marginLeft: '$4',
        },
      },
      normal: {
        borderRadius: '$4',
        padding: '9px 20px',
        // minHeight: '$48',
        'span + span': {
          marginLeft: '$4',
        },
      },
    },
    isDisabled: {
      isDisabled: {
        pointerEvents: 'none',
        backgroundColor: '$mediumGrey',
      },
    },
    isSvgIconWithLabel: {
      true: {
        padding: '11px 20px',

        minHeight: '$48',
        svg: {
          alignSelf: 'flex-end',
          // marginRight: '$8',
        },
      },
    },
    isForDropDown: {
      true: {
        borderRadius: '0',
      },
    },
  },
};

export const ButtonLinkExternalStyled = styled('a', buttonStyled);
export const ButtonLinkStyled = styled(Link, buttonStyled);
export const ButtonActionStyled = styled('button', buttonStyled);

export const ButtonNotificationBubble = styled('span', {
  backgroundColor: '$red',
  br: '50%',
  minWidth: '19px',
  height: '19px',
  fontSize: '$12',
  lineHeight: '0.83em',
  fontFamily: '$ArtifaktElementBold',
  position: 'absolute',
  top: 0,
  right: 0,
  span: {
    display: 'inline-flex',
    position: 'relative',
    color: '$black',
    padding: '$4',
    alignItems: 'center',
  },
});

export const CounterAndSVGWrapper = styled('span', {
  position: 'relative',
  lineHeight: '1em',
  display: 'flex',
  alignItems: 'center',
});
