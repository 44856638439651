import React, { createContext, useState, useContext } from 'react';

const ModalContext = createContext();

export const ModalProvider = ({ children }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalContent, setModalContent] = useState('');
  const [isLoading, setLoading] = useState(false);

  const openModal = content => {
    setModalContent(content);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setModalContent('');
    setIsModalOpen(false);
  };

  return (
    <ModalContext.Provider
      value={{
        isModalOpen,
        modalContent,
        setModalContent,
        openModal,
        closeModal,
        isLoading,
        setLoading,
      }}
    >
      {children}
    </ModalContext.Provider>
  );
};

export const useMigrationModal = () => useContext(ModalContext);
