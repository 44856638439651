import { AuthClient } from '@adsk/identity-web-sdk';
import jwt_decode from 'jwt-decode';

const authClient = new AuthClient({
  client_id: FORGE_CLIENT,
  redirect_uri: `${FE_URL}`,
  env: FORGE_ENV,
  scope: 'data:read',
  useRefreshTokens: true,
});

export const loginWithAdsk = async () => {
  try {
    await authClient.loginWithRedirect();
  } catch {
    console.log(
      `error_code: ${e.error}, error_description: ${e.error_description}`
    );
  }
};

export const authenticateWithPopup = async () => {
  try {
    // it's good practice to await call here
    const accessToken = await authClient.loginWithPopup();
    return accessToken;
  } catch (e) {
    console.log(
      `error_code: ${e.error}, error_description: ${e.error_description}`
    );
  }
};

export const checkAdskSession = async () => {
  try {
    const token = await authClient.getAccessTokenSilently();
    if (token && token.length > 0) {
      return {
        isActive: true,
        token: token,
      };
    }
  } catch (e) {
    console.log(e);
    return {
      isAuthenticated: false,
      token: '',
    };
  }
};

export const closeLoginPopup = () => {
  authClient.closePopup();
};

export const checkUserIsAuthenticated = async () => {
  console.log('Checking user is authenticated');
  try {
    const isAuthenticatedUser = await authClient.isAuthenticated();
    console.log(isAuthenticatedUser);
    return isAuthenticatedUser;
  } catch (e) {
    console.log(
      `Not authenticated error_code: ${e.error}, error_description: ${e.error_description}`
    );
  }
};
export const getTokenSilently = async () => {
  // const isUserAuthenticated = await checkUserIsAuthenticated();
  // console.log('isUserAuthenticated', isUserAuthenticated);
  // if (isUserAuthenticated) {
  console.log('Getting token silently');
  try {
    console.log('Try');
    const accessToken = await authClient.getAccessTokenSilently();
    console.log('accessToken', accessToken);
    return accessToken;
  } catch (e) {
    if (e?.error === 'login_required' || e?.error === undefined) {
      //loginWithAdsk();
    } else {
      console.log(
        `error_code: ${e.error}, error_description: ${e.error_description}`
      );
    }
  }
};
// };

export const getIdTokenSilently = async () => {
  try {
    const idToken = await authClient.getIDTokenSilently();
    const decodedData = jwt_decode(idToken);
    return decodedData;
  } catch (e) {
    console.log(e);
  }
};

export const logoutWithRedirect = async () => {
  try {
    await authClient.logout({
      post_logout_redirect_uri: `${FE_URL}`,
    });
  } catch (e) {
    console.log(
      `error_code: ${e.error}, error_description: ${e.error_description}`
    );
  }
};

export const registerNewAccount = async () => {
  try {
    await authClient.loginWithRedirect({
      options: {
        authoptions: '{"isregext":"true"}',
      },
    });
  } catch {
    console.log(
      `error_code: ${e.error}, error_description: ${e.error_description}`
    );
  }
};

export default {
  loginWithAdsk,
  logoutWithRedirect,
  getTokenSilently,
  checkAdskSession,
  getIdTokenSilently,
  authenticateWithPopup,
};
